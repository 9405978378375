import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['paxFirstName', 'paxLastName', 'field'];

    connect() {
        this.fieldTargets.every((field) => {
            if (field.dataset.invalid !== undefined) {
                window.scrollTo({ top: field.offsetTop, behavior: 'smooth' });
                return false;
            }
            return true;
        });
    }

    setPaxFirstName({ target: { value: firstName } }) {
        if (!this.hasPaxFirstNameTarget) {
            return;
        }

        this.paxFirstNameTarget.value = firstName;
    }

    setPaxLastName({ target: { value: lastName } }) {
        if (!this.hasPaxLastNameTarget) {
            return;
        }

        this.paxLastNameTarget.value = lastName;
    }
}
