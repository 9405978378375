import { Controller } from '@hotwired/stimulus';

export default class FaqController extends Controller {
    static targets = ['faqNav'];

    handleHeadingClick(event) {
        event.preventDefault();
        this.toggleSection(event.currentTarget.closest('.section'));
    }
    handleLinkClick(event) {
        event.preventDefault();

        const href = event.currentTarget.getAttribute('href');
        this.toggleSection(this.element.querySelector(href));
    }

    toggleSection(section) {
        section.classList.add('is-visible');
        Array.from(section.parentNode.children).forEach((sibling) => {
            if (sibling !== section) sibling.classList.remove('is-visible');
        });

        this.faqNavTarget
            .querySelector(`[href="#${section.id}"]`)
            .classList.add('is-active');
        this.faqNavTarget.querySelectorAll(':scope > .link').forEach((link) => {
            if (link.getAttribute('href') !== `#${section.id}`) {
                link.classList.remove('is-active');
            }
        });
    }
}
