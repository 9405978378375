import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash/debounce';

export default class extends Controller {
    static targets = ['textarea', 'users', 'mentionedUsers'];
    static values = {
        usersUrl: String,
    };

    connect() {
        this.users = [];
        this.mentionStart = -1;
        this.currentQuery = '';
        this.selectedUsers = new Map();

        this.handleInput = this.handleInput.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.closeUserList = this.closeUserList.bind(this);

        this.textareaTarget.addEventListener('input', this.handleInput);
        this.textareaTarget.addEventListener(
            'keydown',
            debounce(this.handleKeydown),
        );
        document.addEventListener('click', this.closeUserList);
    }

    disconnect() {
        this.textareaTarget.removeEventListener('input', this.handleInput);
        this.textareaTarget.removeEventListener('keydown', this.handleKeydown);
        document.removeEventListener('click', this.closeUserList);
    }

    async handleInput() {
        const cursorPosition = this.textareaTarget.selectionStart;
        const textBeforeCursor = this.textareaTarget.value.slice(
            0,
            cursorPosition,
        );

        // Check if we're in a mention context
        const lastAtSymbol = textBeforeCursor.lastIndexOf('@');
        if (
            lastAtSymbol !== -1 &&
            !textBeforeCursor.slice(lastAtSymbol + 1).includes(' ')
        ) {
            this.mentionStart = lastAtSymbol;
            this.currentQuery = textBeforeCursor.slice(lastAtSymbol + 1);
            await this.fetchUsers(this.currentQuery);
            this.showUserList();
        } else {
            this.hideUserList();
        }
    }

    handleKeydown(event) {
        if (!this.usersTarget.classList.contains('hidden')) {
            if (event.key === 'Escape') {
                this.hideUserList();
                event.preventDefault();
            } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                this.navigateUserList(event.key === 'ArrowDown');
                event.preventDefault();
            } else if (
                event.key === 'Enter' &&
                this.usersTarget.querySelector('.active')
            ) {
                this.selectUser(this.usersTarget.querySelector('.active'));
                event.preventDefault();
            }
        }
    }

    async fetchUsers(query) {
        try {
            const response = await fetch(
                `${this.usersUrlValue}?term=${encodeURIComponent(query)}`,
                {
                    method: 'GET',
                    'access-control-allow-origin': '*',
                },
            );
            if (!response.ok) throw new Error('Aucun utilisateur trouvé');
            this.users = await response.json();
            this.renderUserList();
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    }

    renderUserList() {
        this.usersTarget.innerHTML = this.users
            .map(
                (user, index) => `
                <div class="mention-item ${index === 0 ? 'active' : ''}"
                     data-user-id="${user.id}"
                     data-action="click->backpack--mention#selectUser"
                     data-username="${user.displayName}">
                    <span>${user.displayName}</span>
                </div>
            `,
            )
            .join('');
    }

    selectUser(event) {
        const userId =
            event instanceof MouseEvent
                ? event.currentTarget.dataset.userId
                : event.dataset.userId;
        const username =
            event instanceof MouseEvent
                ? event.currentTarget.dataset.username
                : event.dataset.username;

        // Update textarea
        const beforeMention = this.textareaTarget.value.slice(
            0,
            this.mentionStart,
        );
        const afterMention = this.textareaTarget.value.slice(
            this.textareaTarget.selectionStart,
        );
        this.textareaTarget.value = `${beforeMention}@${username} ${afterMention}`;

        // Store user ID
        this.selectedUsers.set(username, userId);
        this.updateMentionedUsers();

        this.hideUserList();

        // Set cursor position at the end of text
        const newPosition = this.textareaTarget.value.length + 1;
        this.textareaTarget.setSelectionRange(newPosition, newPosition);
        this.textareaTarget.focus();
    }

    updateMentionedUsers() {
        this.mentionedUsersTarget.value = JSON.stringify(
            Object.fromEntries(this.selectedUsers),
        );
    }

    navigateUserList(down) {
        const items = this.usersTarget.querySelectorAll('.mention-item');
        const currentActive = this.usersTarget.querySelector('.active');
        const currentIndex = Array.from(items).indexOf(currentActive);

        items.forEach((item) => item.classList.remove('active'));

        let newIndex;
        if (down) {
            newIndex = currentIndex + 1 >= items.length ? 0 : currentIndex + 1;
        } else {
            newIndex =
                currentIndex - 1 < 0 ? items.length - 1 : currentIndex - 1;
        }

        items[newIndex].classList.add('active');
        items[newIndex].scrollIntoView({ block: 'nearest' });
    }

    showUserList() {
        this.usersTarget.classList.remove('hidden');

        const textareaRect = this.textareaTarget.getBoundingClientRect();
        const textBeforeMention = this.textareaTarget.value.slice(
            0,
            this.mentionStart,
        );
        const dummySpan = document.createElement('span');
        dummySpan.style.visibility = 'hidden';
        dummySpan.style.position = 'absolute';
        dummySpan.style.whiteSpace = 'pre-wrap';
        dummySpan.style.font = window.getComputedStyle(
            this.textareaTarget,
        ).font;
        dummySpan.textContent = textBeforeMention;

        document.body.appendChild(dummySpan);

        const mentionPos = dummySpan.getBoundingClientRect();
        document.body.removeChild(dummySpan);

        const top = textareaRect.top + window.scrollY + mentionPos.height;
        const left = textareaRect.left + window.scrollX + mentionPos.width;

        this.usersTarget.style.top = `${top}px`;
        this.usersTarget.style.left = `${left}px`;
    }

    hideUserList() {
        this.usersTarget.classList.add('hidden');
        this.mentionStart = -1;
        this.currentQuery = '';
    }

    closeUserList(event) {
        if (!this.element.contains(event.target)) {
            this.hideUserList();
        }
    }
}
