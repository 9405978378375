<script lang="ts">
    import { createDialog } from '@melt-ui/svelte';
    import { fade } from 'svelte/transition';
    import CrossIcon from '$src/icons/CrossIcon.svelte';
    import { flyDrawer } from '$src/utils/customTransitions';

    export let titleLabel: string;
    export let disabled: boolean = false;

    const {
        elements: { trigger, title, overlay, content, close, portalled },
        states: { open },
    } = createDialog({
        forceVisible: true,
    });
</script>

{#if !disabled}
    <span {...$trigger} use:trigger>
        <slot name="triggerlink" />
    </span>
{:else}
    <span>
        <slot name="triggerlink" />
    </span>
{/if}

{#if $open}
    <div class="" {...$portalled} use:portalled>
        <div
            {...$overlay}
            use:overlay
            class="fixed inset-0 z-50 bg-black/50"
            transition:fade={{ duration: 250 }}
        />
        <div
            {...$content}
            use:content
            class="shadow-lg fixed bottom-0 right-0 z-[9999999999] h-[85vh] w-full bg-white transition-all duration-1000 focus:outline-none desktop:top-0 desktop:h-screen desktop:max-w-[485px]"
            transition:flyDrawer
        >
            <div
                class="flex items-center justify-between px-m py-s desktop:px-3xl desktop:py-m"
            >
                <h2 class="text-[22px] font-semibold" {...$title} use:title>
                    {titleLabel}
                </h2>
                <button {...$close} use:close aria-label="Close" class="">
                    <CrossIcon class="size-xl" />
                </button>
            </div>

            <section class="desktop:no-scrollbar h-full overflow-y-scroll">
                <slot />
            </section>
        </div>
    </div>
{/if}
