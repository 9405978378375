import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = [
        'amountInputWrapper',
        'amountInput',
        'amountInputError',
        'predefinedInput',
        'buyerEmailInputError',
        'recipientInputError',
        'buyerNameInputError',
    ];

    isOpen = false;

    connect() {
        this.element.addEventListener('input', () => {
            this.clearErrors();
        });
    }

    selectAmount(event) {
        const amount = event.params.amount;
        if (amount) {
            this.amountInputTarget.value = `${amount}`;
        }
        if (this.isOpen) {
            this.amountInputWrapperTarget.classList.add('hidden');
            this.isOpen = false;
        }
    }

    showCustomInput() {
        // open and reset input value
        if (this.predefinedInputTarget.checked && !this.isOpen) {
            this.amountInputWrapperTarget.classList.remove('hidden');
            this.amountInputWrapperTarget.classList.add('flex');
            this.amountInputTarget.value = '';
            this.isOpen = true;
        }
    }

    async submit(event) {
        event.preventDefault();

        const form = event.target;
        const data = new FormData(form);

        // Clear previous error messages
        this.clearErrors();

        // in data delete the entry predefined-amount
        data.delete('predefined-amount');
        document
            .getElementsByName('predefined-amount')
            .forEach((input) => (input.disabled = true));

        // Validate inputs
        const amount = data.get('amount');
        const recipient = data.get('recipient');
        const buyerName = data.get('buyerName');
        const buyerEmail = data.get('buyerEmail');
        let hasError = false;

        if (!amount) {
            this.amountInputWrapperTarget.classList.remove('hidden');
            this.amountInputWrapperTarget.classList.add('flex');
            this.amountInputErrorTarget.classList.remove('hidden');
            this.amountInputTarget.classList.add('!border-status-negative');
            this.amountInputErrorTarget.textContent = 'Champ requis';
            hasError = true;
        }

        if (amount < 10) {
            this.amountInputWrapperTarget.classList.remove('hidden');
            this.amountInputWrapperTarget.classList.add('flex');
            this.amountInputErrorTarget.classList.remove('hidden');
            this.amountInputTarget.classList.add('!border-status-negative');
            this.amountInputErrorTarget.textContent =
                'Saisissez un montant de 10 € minimum';
            hasError = true;
        }

        if (!recipient) {
            this.recipientInputErrorTarget.classList.remove('hidden');
            this.recipientInputErrorTarget.previousElementSibling?.classList.add(
                '!border-status-negative',
            );
            this.recipientInputErrorTarget.textContent = 'Champ requis';
            hasError = true;
        }

        if (!buyerName) {
            this.buyerNameInputErrorTarget.classList.remove('hidden');
            this.buyerNameInputErrorTarget.previousElementSibling?.classList.add(
                '!border-status-negative',
            );
            this.buyerNameInputErrorTarget.textContent = 'Champ requis';
            hasError = true;
        }

        if (!buyerEmail || !this.validateEmail(buyerEmail)) {
            this.buyerEmailInputErrorTarget.classList.remove('hidden');
            this.buyerEmailInputErrorTarget.previousElementSibling?.classList.add(
                '!border-status-negative',
            );
            this.buyerEmailInputErrorTarget.textContent =
                'Adresse mail invalide';
            hasError = true;
        }

        if (!this.hasValidLength(recipient, 50)) {
            this.recipientInputErrorTarget.classList.remove('hidden');
            this.recipientInputErrorTarget.previousElementSibling?.classList.add(
                '!border-status-negative',
            );
            this.recipientInputErrorTarget.textContent =
                'Ce champ ne doit pas dépasser 50 caractères';
            hasError = true;
        }

        if (!this.hasValidLength(buyerName, 50)) {
            this.buyerNameInputErrorTarget.classList.remove('hidden');
            this.buyerNameInputErrorTarget.previousElementSibling?.classList.add(
                '!border-status-negative',
            );
            this.buyerNameInputErrorTarget.textContent =
                'Ce champ ne doit pas dépasser 50 caractères';
            hasError = true;
        }

        if (hasError) {
            return;
        }

        this.element.submit();
    }

    clearErrors() {
        document
            .getElementsByName('predefined-amount')
            .forEach((input) => (input.disabled = false));

        this.amountInputErrorTarget.classList.add('hidden');
        this.amountInputErrorTarget.textContent = '';
        this.recipientInputErrorTarget.classList.add('hidden');
        this.recipientInputErrorTarget.textContent = '';
        this.buyerNameInputErrorTarget.classList.add('hidden');
        this.buyerNameInputErrorTarget.textContent = '';
        this.buyerEmailInputErrorTarget.classList.add('hidden');
        this.buyerEmailInputErrorTarget.textContent = '';

        this.amountInputTarget.classList.remove('!border-status-negative');
        this.recipientInputErrorTarget.previousElementSibling?.classList.remove(
            '!border-status-negative',
        );
        this.buyerNameInputErrorTarget.previousElementSibling?.classList.remove(
            '!border-status-negative',
        );
        this.buyerEmailInputErrorTarget.previousElementSibling?.classList.remove(
            '!border-status-negative',
        );
    }

    validateEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }

    hasValidLength(input, maxLength) {
        return input.length <= maxLength;
    }
}
