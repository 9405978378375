<script lang="ts">
    import dayjs from 'dayjs';
    import type { Dayjs } from 'dayjs';
    import {
        VpArrowLeftCircleIcon,
        VpArrowRightCircleIcon,
    } from '@decathlon-experience/icons/svelte';
    import { fade } from 'svelte/transition';

    import cn from '$src/utils/cn';
    import {
        getQuantileSizeClass,
        isMonthDatesFull,
        isSelectedMonth,
    } from '$src/views/Tour/VisibleDates/utils';
    import { formatCurrency } from '$src/utils/price';
    import {
        MinPriceMonth,
        VisibleDate,
    } from '$src/views/Tour/VisibleDates/types';

    export let root,
        prevButton,
        nextButton,
        page,
        range,
        currentActiveMonth: Dayjs,
        totalPages,
        minPricePerMonths: MinPriceMonth[],
        // FIT does not have visibleDates
        visibleDates: VisibleDate[] | undefined = undefined;
</script>

<div
    class="flex w-full items-center justify-center gap-xs py-xs medium:gap-m"
    {...$root}
    use:root
>
    {#if $totalPages > 1}
        <button class="" {...$prevButton} use:prevButton>
            <VpArrowLeftCircleIcon
                class={cn(
                    'size-xl text-content-brand',
                    $page === 1 && 'opacity-[0.38]',
                )}
            />
        </button>
    {/if}

    {#each minPricePerMonths as minPriceMonth, index}
        <button
            transition:fade
            on:click={() => (currentActiveMonth = minPriceMonth.date)}
            class={cn(
                'relative w-full max-w-[60px] flex-col items-center justify-center gap-2xs rounded-[20px] border border-solid border-border-quiet p-xs',
                isSelectedMonth(minPriceMonth.date, currentActiveMonth) &&
                    'border-border-brand text-content-brand',
                !(index >= $range.start && index <= $range.end)
                    ? 'hidden'
                    : 'flex',
                minPriceMonth.price == null && 'opacity-[0.38]',
                isMonthDatesFull(visibleDates, minPriceMonth.date) &&
                    'opacity-[0.38]',
            )}
        >
            <span class="px-2xs text-sm">
                {dayjs(minPriceMonth.date).format('MMM YYYY')}
            </span>
            <span
                class={cn(
                    'h-[1px] w-full border-t border-solid border-border-quiet',
                    isSelectedMonth(minPriceMonth.date, currentActiveMonth) &&
                        'border-border-brand',
                )}
            ></span>
            <span class="whitespace-nowrap text-xs">
                {minPriceMonth.price
                    ? formatCurrency(minPriceMonth.price / 100, false)
                    : '-'}
            </span>
            {#if minPriceMonth.price != null && minPriceMonth.quantile != null}
                <span
                    class={cn(
                        'w-[15px] rounded-full border-b-[2px] border-solid',
                        getQuantileSizeClass(minPriceMonth.quantile),
                    )}
                >
                </span>
            {/if}
        </button>
    {/each}
    {#if $totalPages > 1}
        <button class="" {...$nextButton} use:nextButton>
            <VpArrowRightCircleIcon
                class={cn(
                    'size-xl text-content-brand',
                    $page === $totalPages && 'opacity-[0.38]',
                )}
            />
        </button>
    {/if}
</div>
