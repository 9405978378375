<script lang="ts">
    import { writable } from 'svelte/store';

    import CrossIcon from '$src/icons/CrossIcon.svelte';
    import Modal from '$src/components/Modal.svelte';
    import { ariaKeyDownA11yHandler } from '$src/utils/ariaKeyDownA11y';
    import cn from '$src/utils/cn';
    import CloseCircle from '$src/icons/CloseCircle.svelte';
    import PaxFilterForm from '$src/components/Filters/PaxFilterForm.svelte';
    import Button from '$src/components/Button.svelte';
    import FamilyIcon from '$src/icons/FamilyIcon.svelte';
    import { PaxFilterValues } from '../types';

    export let adultsValue: string;
    export let childrenValue: string;
    export let childrenAges: string[];
    export let handleSubmitValues: (values: PaxFilterValues) => void;

    let isModalOpen = writable(false);

    $: totalPax = Number(adultsValue) + Number(childrenValue);

    const openModal = () => {
        $isModalOpen = true;
    };
</script>

<div
    on:click={openModal}
    {...ariaKeyDownA11yHandler(openModal)}
    role="button"
    tabindex="0"
    class="flex justify-between gap-1 bg-container-quiet px-m py-xs desktop:w-auto"
>
    <div class="flex w-full items-center">
        <span class="flex font-semibold leading-6 text-content-neutral">
            <span class="mr-xs flex items-center">
                <FamilyIcon class="size-m" />
            </span>
            Voyageurs
        </span>
        <div
            class="w-full whitespace-nowrap text-right leading-6 text-content-neutral"
        >
            {#if totalPax > 0}
                {totalPax} voyageur{totalPax > 1 ? 's' : ''}
            {:else}
                &nbsp;<span class="text-rock-200">Ex: 1 voyageur</span>
            {/if}
        </div>
        <button
            type="button"
            class={cn('ml-xs', totalPax === 0 && 'hidden')}
            on:click|preventDefault|stopPropagation={() => {
                adultsValue = '';
                childrenValue = '';
                childrenAges = [];
            }}
        >
            <CloseCircle class="size-m fill-content-quiet" />
        </button>
    </div>
</div>
<Modal bind:isOpen={isModalOpen}>
    <div slot="header" class="item-center flex justify-between">
        <div class="m-auto flex font-semibold text-content-neutral">
            Sélectionner un nombre de voyageurs
        </div>
        <button
            on:click={() => ($isModalOpen = false)}
            class="focus-visible:outline-none"
        >
            <CrossIcon class="size-xl fill-content-neutral" />
        </button>
    </div>

    <div
        class="fixed mt-m h-[calc(100vh-76px)] w-full overflow-scroll p-2xl pb-6xl"
    >
        <PaxFilterForm
            {childrenAges}
            {childrenValue}
            {adultsValue}
            handleSubmit={(values) => {
                handleSubmitValues(values);
                $isModalOpen = false;
            }}
        >
            <!--  Footer validate button -->
            <div
                slot="footer"
                class="fixed bottom-0 left-0 flex w-full justify-center border-t border-solid border-commercial-neutral bg-white px-2xl py-m"
            >
                <Button class="w-full" type="submit">Valider</Button>
            </div>
        </PaxFilterForm>
    </div>
</Modal>
