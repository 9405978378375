import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['button1', 'button2', 'block1', 'block2'];

    connect() {
        // Initial setup if needed
    }

    showBlock1() {
        this.block1Target.classList.remove('hidden');
        this.button1Target.classList.remove('vp-button--tertiary');
        this.block2Target.classList.add('hidden');
        this.button2Target.classList.add('vp-button--tertiary');
    }

    showBlock2() {
        this.block1Target.classList.add('hidden');
        this.button1Target.classList.add('vp-button--tertiary');
        this.block2Target.classList.remove('hidden');
        this.button2Target.classList.remove('vp-button--tertiary');
    }
}
