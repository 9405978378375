<script lang="ts">
    import Drawer from '$src/components/Drawer.svelte';

    export let label, dict, isLowCarbon;
</script>

<Drawer titleLabel="Contribution à la neutralité carbone mondiale">
    <div slot="triggerlink">
        <div class="cursor-pointer underline hover:no-underline">
            {label}
        </div>
    </div>

    <div class="flex flex-col gap-m px-m py-s desktop:px-3xl desktop:py-m">
        <div
            class="border-b border-solid border-border-quiet pb-xs text-[20px] font-semibold"
        >
            Explications
        </div>
        <p>{dict.low_carbon_description}</p>
        <a
            class="cursor-pointer underline hover:no-underline"
            href="/info/comprendre-lempreinte-carbone-de-mon-voyage-14.html"
        >
            {dict.low_carbon_link}
        </a>
        {#if isLowCarbon}
            <p>{dict.low_carbon_100}</p>
            <a
                class="cursor-pointer underline hover:no-underline"
                href="/voyages/theme-bas-carbone"
            >
                {dict.low_carbon_tours}
            </a>
        {/if}
    </div>
</Drawer>
