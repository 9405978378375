// controllers/drag_drop_controller.js
import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

export default class extends Controller {
    static targets = ['sortableContainer'];

    sortable;

    connect() {
        const blockPositions = Array.from(
            this.sortableContainerTarget.children,
        ).map((block) => block.dataset.blockId);
        this.sortable = Sortable.create(this.sortableContainerTarget, {
            chosenClass: 'bg-container-quiet', // Add a class when block is selected
            onEnd: (evt) => {
                const blockPositions = Array.from(
                    this.sortableContainerTarget.children,
                ).map((block) => block.dataset.blockId);

                if (blockPositions) {
                    blockPositions.forEach((blockId, index) => {
                        const inputBlockId = document.querySelector(
                            `[data-input-block-id="${blockId}"]`,
                        );
                        if (inputBlockId) {
                            inputBlockId.value = index;
                        }
                    });
                }
            },
        });
    }
}
