import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
require('dayjs/locale/fr');
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.locale('fr');

export interface DateRange {
    startDate: Dayjs;
    endDate: Dayjs;
}

// tour date format is DD/MM/YYYY
export const formatTourDate = (date: string) => {
    return dayjs(date).format('DD MMM');
};

export const formatTourDate2 = (date: string) => {
    return dayjs(date).format('DD MMM YYYY');
};

/**
 * Return the first available month index (dayjs index starts at 0)
 * @param unavailableDates
 * @returns dayjs Date
 */
export const findFirstAvailableMonth = (unavailableDates: DateRange[]) => {
    // Sort the unavailable periods by start date
    unavailableDates.sort((a, b) =>
        a.startDate.isBefore(b.startDate) ? -1 : 1,
    );

    // Start from the current date
    let currentDate = dayjs(); // Set to the first day of the current month

    for (const period of unavailableDates) {
        if (currentDate.isBefore(period.startDate)) {
            return currentDate;
        }

        // if our current date is within the unavailable period, we move to the end of the period + 1 day
        if (currentDate.isSameOrBefore(period.endDate)) {
            currentDate = period.endDate.add(1, 'day');
        }
    }

    return currentDate;
};
