<script lang="ts">
    import { VpInformationIcon } from '@decathlon-experience/icons/svelte';

    import { VisibleDate } from '$src/views/Tour/VisibleDates/types';
    import Drawer from '$src/components/Drawer.svelte';
    import { formatCurrency } from '$src/utils/price';
    import cn from '$src/utils/cn';

    export let ageRangePrices: VisibleDate['ageRangePrices'];
    export let disabled: boolean;
</script>

<Drawer titleLabel="Autres tarifs" {disabled}>
    <div
        slot="triggerlink"
        class={cn(
            'flex gap-2xs border-l border-solid border-border-quiet p-s text-sm underline',
            !disabled && 'hover:no-underline',
        )}
    >
        Autres tarifs
        <VpInformationIcon class="size-l" />
    </div>

    <div class="mb-[15vh] mt-m flex flex-col gap-m px-l desktop:px-3xl">
        {#each ageRangePrices as { id, name, price } (id)}
            <div
                class="flex w-full flex-col justify-between gap-xs border-t border-solid border-border-quiet py-xs"
            >
                <span class="font-semibold">{name}</span>
                <span>{formatCurrency(price.amount / 100)} par voyageur</span>
            </div>
        {/each}
    </div>
</Drawer>
