import type { DefaultOptions, FilteredValues } from '$src/types/search.types';
// $src impossible, weird compilation bug?

export const flattenSportsFilter = (sports: DefaultOptions) => {
    const flattenRecursive = (options: DefaultOptions): DefaultOptions => {
        let flattened: DefaultOptions = [];
        options.forEach((option) => {
            flattened.push({
                value: option.value,
                label: option.label,
                hidden: option.hidden,
            });
            if (option.children && option.children.length > 0) {
                flattened = flattened.concat(flattenRecursive(option.children));
            }
        });
        return flattened;
    };

    return flattenRecursive(sports).sort((a, b) =>
        a.label.localeCompare(b.label),
    );
};

/**
 * Takes a search query string and find all the options that match the query
 * return the list, if query string is empty then returns whole defaultOptions list
 * @param query
 * @param defaultOptions
 */
export const filterOptions = ({
    query,
    defaultOptions,
}: {
    query: string;
    defaultOptions: DefaultOptions;
}) => {
    if (query !== '' && defaultOptions) {
        return defaultOptions.reduce((list, currentOption) => {
            if (
                currentOption.label.toLowerCase().includes(query.toLowerCase())
            ) {
                list = [
                    ...list,
                    {
                        label: currentOption.label,
                        value: currentOption.value,
                    },
                ];
            }

            return list.sort((a, b) => a.label.localeCompare(b.label));
        }, [] as FilteredValues);
    } else {
        return defaultOptions.sort((a, b) => a.label.localeCompare(b.label));
    }
};
