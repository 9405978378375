import { FilterFormLabels, FilterFormValues } from './types';
import {
    DefaultOptionList,
    DefaultOptions,
    FilteredValues,
} from '$src/types/search.types';
import { defaultFormValues } from './constants';

/**
 * Format selected value ids to their corresponding label
 * @param currentValue
 * @param options
 */
export const findCurrentSelectedFilterLabel = (
    currentValue: string,
    options: FilteredValues | DefaultOptions,
) => {
    if (currentValue === '') return '';

    return (
        options.find((currentOption) => currentOption.value === currentValue)
            ?.label ?? ''
    );
};

/**
 * Initialize form with selected values
 * @param values
 * @param defaultOptions
 */
export const initializeLabelValues = (
    values: FilterFormValues,
    defaultOptions: DefaultOptionList,
): FilterFormLabels => {
    const destinationLabel = findCurrentSelectedFilterLabel(
        values.destination,
        defaultOptions.destinations,
    );
    const sportLabel = findCurrentSelectedFilterLabel(
        values.theme,
        defaultOptions.sports,
    );

    // Secondary filters
    const levelsLabel = values.levels
        .map((val) =>
            findCurrentSelectedFilterLabel(val, defaultOptions.levels),
        )
        .join(', ');

    const tagsLabel = values.tags
        .map((val) => findCurrentSelectedFilterLabel(val, defaultOptions.tags))
        .join(', ');

    const typesLabel = values.types
        .map((val) => findCurrentSelectedFilterLabel(val, defaultOptions.types))
        .join(', ');

    return {
        destination: destinationLabel,
        theme: sportLabel,
        startDate: values.startDate,
        nbPax: values.adults,
        // secondary
        levels: levelsLabel,
        types: typesLabel,
        duration: `${String(values.durationMin ?? defaultOptions.durationMin)} -
            ${String(values.durationMax ?? defaultOptions.durationMax)}`,
        budget: `${String(
            values.budgetMin ?? defaultOptions.budgetMin,
        )} - ${String(values.budgetMax ?? defaultOptions.budgetMax)}`,
        tags: tagsLabel,
    };
};

/**
 * Reset form with initial default values
 */
export const resetLabels = () => ({
    destination: '',
    theme: '',
    startDate: undefined,
    nbPax: '',
    // secondary
    levels: '',
    types: '',
    duration: '',
    budget: '',
    tags: '',
});

export const resetValues = () => defaultFormValues;
