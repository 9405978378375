import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['select', 'textarea'];

    updateTextarea() {
        const selectedOption = this.selectTarget.selectedOptions[0];
        this.textareaTarget.value = selectedOption.dataset.content || '';
    }
}
