import { Controller } from '@hotwired/stimulus';

/**
 * Dirty fix as Onepay v2 cannot let us override the translations
 */
export default class PaymentController extends Controller {
    connect() {
        const targetNode = this.element;
        const config = { childList: true, subtree: true };
        let foundText = false,
            foundImage = false,
            foundKnowMore = false,
            foundFootnote = false;

        const callback = (mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    const addedNodes = Array.from(mutation.addedNodes);
                    for (let node of addedNodes) {
                        if (
                            !foundImage &&
                            node.tagName === 'IMG' &&
                            node.getAttribute('alt') === 'sportycoins-giftcard'
                        ) {
                            const cloned = node.cloneNode();
                            cloned.src =
                                'https://media.decathlontravel.com/assets/768x0/DPAZ49.webp';
                            cloned.alt = 'Decathlon Travel Gift Card';
                            node.parentNode.append(cloned);
                            // remove original node
                            node.remove();
                            foundImage = true;
                        }
                        if (
                            !foundText &&
                            node.tagName === 'DIV' &&
                            node.textContent === 'Cartes cadeaux'
                        ) {
                            node.textContent =
                                'Cartes cadeaux Decathlon Travel';
                            foundText = true;
                        }

                        if (
                            !foundKnowMore &&
                            node.tagName === 'P' &&
                            node.textContent.includes(
                                'En savoir plus sur la carte cadeau Decathlon',
                            )
                        ) {
                            node.textContent =
                                'En savoir plus sur la carte cadeau Decathlon Travel';
                            foundKnowMore = true;
                        }

                        if (
                            !foundFootnote &&
                            node.tagName === 'P' &&
                            node.textContent.includes(
                                'Je paye tout ou partie de ma commande avec mes cartes cadeaux',
                            )
                        ) {
                            node.textContent =
                                'Je règle tout ou une partie de ma commande avec mes cartes cadeaux Decathlon Travel et je complète, si nécessaire, avec un autre mode de paiement. ⚠️ Attention, seules les cartes cadeaux Decathlon Travel sont acceptées. Les cartes cadeaux Decathlon ne sont pas valides. Vous pouvez acheter des cartes Decathlon Travel ';
                            const link = document.createElement('a');
                            link.href = '/carte-cadeau';
                            link.target = '_blank';
                            link.textContent = 'ici.';
                            link.classList.add(
                                'vtmn-link',
                                'vtmn-typo_text-2',
                                'vtmn-link--standalone',
                                'vtmn-link_size--small',
                            );
                            link.style = 'font-size:12px';
                            node.appendChild(link);
                            foundFootnote = true;
                        }

                        if (
                            foundImage &&
                            foundText &&
                            foundKnowMore &&
                            foundFootnote
                        ) {
                            observer.disconnect();
                            return;
                        }
                    }
                }
            }
        };
        const observer = new MutationObserver(callback);
        observer.observe(targetNode, config);
    }
}
