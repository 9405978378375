<script lang="ts">
    import PaxFilterMobile from './mobile/PaxFilterMobile.svelte';
    import PaxFilterDesktop from '$src/components/Filters/desktop/PaxFilterDesktop.svelte';
    import { PaxFilterValues } from './types';

    let adultsValue = '0';
    let childrenValue = '0';
    let childrenAges: string[] = [];

    const handleSubmitValues = (values: PaxFilterValues) => {
        adultsValue = values.adultsValue;
        childrenValue = values.childrenValue;
        childrenAges = values.childrenAges;
    };
</script>

<div class="hidden w-full desktop:block">
    <PaxFilterDesktop
        {adultsValue}
        {childrenValue}
        {childrenAges}
        {handleSubmitValues}
    />
</div>

<div class="desktop:hidden">
    <PaxFilterMobile
        {adultsValue}
        {childrenValue}
        {childrenAges}
        {handleSubmitValues}
    />
</div>

<input
    aria-hidden="true"
    tabindex="-1"
    class="hidden"
    name="nbPax"
    value={Number(adultsValue) + Number(childrenValue)}
/>

<!-- TODO dissociate nbPax in children and adults -->
<!--<input-->
<!--    aria-hidden="true"-->
<!--    tabindex="-1"-->
<!--    class="hidden"-->
<!--    name="adults"-->
<!--    value={adultsValue}-->
<!--/>-->

<!--<input-->
<!--    aria-hidden="true"-->
<!--    tabindex="-1"-->
<!--    class="hidden"-->
<!--    name="children"-->
<!--    value={childrenValue}-->
<!--/>-->

<!--{#each childrenAges as childrenAge}-->
<!--    <input-->
<!--        aria-hidden="true"-->
<!--        tabindex="-1"-->
<!--        class="hidden"-->
<!--        name="childrenAges[]"-->
<!--        value={childrenAge}-->
<!--    />-->
<!--{/each}-->
