<script lang="ts">
    import { ComponentType } from 'svelte';

    import { DefaultOptions } from '$src/types/search.types';
    import SelectFilterDesktop from '$src/components/Filters/desktop/SelectFilterDesktop.svelte';

    import SelectFilterMobile from './mobile/SelectFilterMobile.svelte';
    import { filterOptions } from '$src/utils/searchFilters';

    export let defaultOptions: DefaultOptions;
    export let inputLabel: string;
    export let inputName: string;
    export let menuTitle: string | undefined;
    export let icon: ComponentType;
    export let placeholder: string;
    export let placeholderMobile: string;

    let inputValue: string = '';

    let filteredOptions: DefaultOptions = defaultOptions;

    const handleFilterOptions = (query: string) => {
        filteredOptions = filterOptions({ query, defaultOptions });
    };

    const onInputChange = (event: Event) =>
        handleFilterOptions((event.target as HTMLInputElement).value);

    const resetInput = () => {
        inputValue = '';
        // reset filtered options
        handleFilterOptions('');
    };
</script>

<div class="hidden w-full desktop:block">
    <SelectFilterDesktop
        bind:inputValue
        {inputLabel}
        {menuTitle}
        {filteredOptions}
        {onInputChange}
        {resetInput}
        {icon}
        {placeholder}
    />
</div>

<div class="desktop:hidden">
    <SelectFilterMobile
        bind:inputValue
        {inputLabel}
        {menuTitle}
        {filteredOptions}
        {onInputChange}
        {resetInput}
        {icon}
        {placeholder}
        {placeholderMobile}
    />
</div>

<input
    aria-hidden="true"
    tabindex="-1"
    class="hidden"
    name={inputName}
    value={inputValue}
/>
