import { Controller } from '@hotwired/stimulus';
import { toggleCSSClasses } from '../../js/utils';

export default class extends Controller {
    static targets = ['modal', 'btn'];
    link = null;
    confirm(event) {
        event.preventDefault();
        toggleCSSClasses(this.modalTarget, 'hidden', 'flex');
        // <a> tag
        if (this.link) window.open(this.link, '_self');
        else if (this.hasBtnTarget) {
            // if submit <button>
            if (this.btnTarget.hasAttribute('form')) {
                document
                    .getElementById(this.btnTarget.getAttribute('form'))
                    .submit();
            }
        }
    }

    cancel() {
        event.preventDefault();
        toggleCSSClasses(this.modalTarget, 'hidden', 'flex');
    }

    toggle(event) {
        if (event?.params?.link) {
            this.link = event.params.link;
        }
        // if button is type submit
        if (this.hasBtnTarget) {
            if (this.btnTarget.type === 'submit') {
                // if form is invalid, don't show modal
                if (!this.btnTarget.form.checkValidity()) return;
            }
        }
        event.preventDefault();
        toggleCSSClasses(this.modalTarget, 'hidden', 'flex');
    }
}
