import type { Dayjs } from 'dayjs';

export enum Quantile {
    HIGH = 'high',
    MEDIUM = 'medium',
    LOW = 'low',
    NONE = 'none',
}

export type MinPriceMonth = {
    date: Dayjs;
    price: number;
    quantile?: Quantile;
};

export type MinPriceDay = {
    amount: number;
    quantile?: Quantile;
};

export type MinPriceDays = {
    [date: string]: MinPriceDay | null;
};

export type VisibleDate = {
    id: string;
    tour: number;
    date1: {
        date: string;
        timezone_type: number;
        timezone: string;
    };
    date2: {
        date: string;
        timezone_type: number;
        timezone: string;
    };
    status: number;
    price_EUR: number;
    price: number;
    priceWithCustomerCarbonContribution: number;
    rebatedPrice: number | null;
    rebatedPriceWithCustomerCarbonContribution: number | null;
    rebatePercentage: number | null;
    rebateAmount: number | null;
    hasRebate: boolean;
    isGuaranteed: boolean;
    isAvailable: boolean;
    statusName: string;
    isFull: boolean;
    countPaxNeededToConfirm: number;
    remaining_stock: number;
    ageRangePrices: {
        id: string;
        name: string;
        price: {
            amount: number;
        };
    }[];
};

export type Dict = {
    dates: {
        title: string;
        start: string;
        description: string;
        price_per_age: string;
        months_select: string;
        showmore: string;
        showless: string;
        dates_info: string;
        full: string;
        empty: {
            title: string;
            description: string;
        };
    };
    status: {
        confirm: string;
        available: string;
    };
    sale: {
        closed: string;
        see_travels: string;
    };
};
