<script lang="ts">
    import dayjs from 'dayjs';
    import {
        VpPriceTagIcon,
        VpChevronRightIcon,
        VpArrowDownIcon,
        VpArrowUpIcon,
    } from '@decathlon-experience/icons/svelte';
    import { createPagination } from '@melt-ui/svelte';
    import { fade } from 'svelte/transition';

    import { isModalOpen, modalExtraData } from '$src/store/modal';
    import { formatTourDate, formatTourDate2 } from '$src/utils/date';
    import cn from '$src/utils/cn';
    import { formatCurrency } from '$src/utils/price';
    import {
        getCurrentOrNextAvailableMonth,
        isDateRemainingStockAvailable,
        isDateRemainingStockLast,
    } from '$src/views/Tour/VisibleDates/utils';
    import Button from '$src/components/Button.svelte';
    import {
        Dict,
        MinPriceMonth,
        VisibleDate,
    } from '$src/views/Tour/VisibleDates/types';
    import MinPricePerMonthsList from '$src/views/Tour/VisibleDates/MinPricePerMonthsList.svelte';
    import CalendarLegend from '$src/views/Tour/VisibleDates/Fit/CalendarLegend.svelte';
    import UnavailableTour from '$src/views/Tour/VisibleDates/UnavailableTour.svelte';

    export let tourDatesInfo: string,
        visibleDates: VisibleDate[],
        dict: Dict,
        searchUrl: string,
        minPricePerMonths: MinPriceMonth[],
        isBookable: boolean;

    let isShowingMore = false;

    const {
        elements: { root, prevButton, nextButton },
        states: { range, totalPages, page },
    } = createPagination({
        count: minPricePerMonths.length - 1,
        perPage: 3,
    });
    let currentActiveMonth = getCurrentOrNextAvailableMonth(
        minPricePerMonths,
        visibleDates,
    );
    let filteredDatesByMonth: VisibleDate[] = [];

    $: {
        filteredDatesByMonth = visibleDates.filter((visibleDate) =>
            dayjs(visibleDate.date1.date).isSame(currentActiveMonth, 'month'),
        );
    }

    const handleSelectedDate = (date: VisibleDate) => {
        $isModalOpen = true;
        $modalExtraData = {
            selectedDate: dayjs(date.date1.date).format('YYYY-MM-DD'),
            priceWithCustomerCarbonContribution: date.hasRebate
                ? date.rebatedPriceWithCustomerCarbonContribution
                : date.priceWithCustomerCarbonContribution,
            remainingStock: date?.remaining_stock ?? '',
            statusName: date?.statusName ?? '',
        };
    };
</script>

<div class="desktop:hidden">
    {#if visibleDates.length > 0}
        <h2 class="mb-xs text-[26px] font-semibold text-content-neutral">
            {dict.dates.title}
        </h2>
        <div class="bg-white p-s">
            <div class="flex flex-col gap-m">
                <h3 class="text-[22px] font-semibold">{dict.dates.start}</h3>
                <span class="text-justify">{dict.dates.description}</span>
                {#if visibleDates.find((date) => date.ageRangePrices.length > 1) != null}
                    <div class="flex gap-xs">
                        <div class="self-center">
                            <VpPriceTagIcon class="size-xl" />
                        </div>
                        {dict.dates.price_per_age}
                    </div>
                {/if}
                <span class="text-center font-semibold">
                    {dict.dates.months_select}
                </span>
                <MinPricePerMonthsList
                    {root}
                    {prevButton}
                    {nextButton}
                    {page}
                    {range}
                    bind:currentActiveMonth
                    {totalPages}
                    {minPricePerMonths}
                    {visibleDates}
                />
                <CalendarLegend />
            </div>

            <div class="mt-m border-t border-solid border-border-quiet">
                {#each filteredDatesByMonth as date, index (date.id)}
                    <button
                        in:fade
                        class={cn(
                            'flex w-full border-b border-solid border-border-quiet py-xs',
                            !isShowingMore && index > 4 && 'hidden',
                        )}
                        disabled={date.isFull}
                        on:click={() =>
                            !date.isFull && handleSelectedDate(date)}
                    >
                        <span class="flex w-full flex-col gap-xs text-left">
                            <span class={cn(date.isFull && 'opacity-[0.38]')}>
                                Du <span class="font-semibold">
                                    {formatTourDate(date.date1.date)}
                                </span>
                                au
                                <span class="font-semibold">
                                    {formatTourDate2(date.date2.date)}
                                </span>
                            </span>
                            {#if date.isFull}
                                <div class="flex flex-col gap-2xs">
                                    <span class="font-semibold">Complet</span>
                                    <span
                                        class="text-sm uppercase text-content-brand opacity-[0.38]"
                                    >
                                        Indisponible
                                    </span>
                                </div>
                            {:else}
                                <div class="flex flex-col gap-2xs">
                                    <span class="font-semibold">
                                        {date.statusName}
                                    </span>
                                    {#if isDateRemainingStockAvailable(date)}
                                        <span
                                            class="text-sm uppercase text-content-brand"
                                        >
                                            {date.remaining_stock} place{!isDateRemainingStockLast(
                                                date,
                                            )
                                                ? 's'
                                                : ''} dispo
                                        </span>
                                    {/if}
                                </div>
                            {/if}
                            <span
                                class={cn(
                                    'flex items-center gap-xs',
                                    date.isFull && 'opacity-[0.38]',
                                )}
                            >
                                {#if !isBookable}
                                    <span class="text-xs">À partir de</span>
                                {/if}
                                {#if date.hasRebate}
                                    <span class="line-through">
                                        {formatCurrency(
                                            date.priceWithCustomerCarbonContribution,
                                        )}
                                    </span>
                                    <span
                                        class="w-fit bg-container-commercial-catchy p-2xs text-xs"
                                    >
                                        {date.rebateAmount}
                                    </span>

                                    <span class="pl-xs font-semibold">
                                        {formatCurrency(
                                            date.rebatedPriceWithCustomerCarbonContribution ??
                                                0,
                                        )}
                                    </span>
                                {:else}
                                    <span class="font-semibold">
                                        {formatCurrency(
                                            date.priceWithCustomerCarbonContribution,
                                        )}
                                    </span>
                                {/if}
                            </span>
                        </span>
                        <span class="m-auto h-full">
                            <VpChevronRightIcon
                                class={cn(
                                    'size-l text-content-neutral',
                                    date.isFull &&
                                        'text-content-quiet opacity-[0.38]',
                                )}
                            />
                        </span>
                    </button>
                {:else}
                    <div in:fade class="mt-xs text-center flex flex-col gap-xs">
                        <span class="font-semibold">
                            {dict.dates.empty.title}
                        </span>
                        <span class="text-sm">
                            {dict.dates.empty.description}
                        </span>
                    </div>
                {/each}
            </div>
            {#if filteredDatesByMonth.length > 5}
                <div class="my-m flex items-center justify-center">
                    <Button
                        intent="tertiary"
                        size="small"
                        endIcon={!isShowingMore
                            ? VpArrowDownIcon
                            : VpArrowUpIcon}
                        on:click={() => (isShowingMore = !isShowingMore)}
                    >
                        {#if !isShowingMore}
                            {dict.dates.showmore}
                        {:else}
                            {dict.dates.showless}
                        {/if}
                    </Button>
                </div>
            {/if}
        </div>
    {:else}
        <UnavailableTour {dict} {searchUrl} />
    {/if}
    {#if tourDatesInfo}
        <div class="info">
            <h3 class="heading">{dict.dates.dates_info}</h3>
            <div class="content is-rt">
                <!--  eslint-disable-next-line svelte/no-at-html-tags -->
                {@html tourDatesInfo}
            </div>
        </div>
    {/if}
</div>
