<script lang="ts">
</script>

<div class="mx-auto mt-2xl flex w-fit gap-m text-xs">
    <div class="flex flex-col items-center gap-2xs">
        <div class="h-[3px] w-[20px] bg-status-warning"></div>
        <div>Plus cher</div>
    </div>
    <div class="flex flex-col items-center gap-2xs">
        <div class="h-[3px] w-[20px] bg-container-commercial-catchy"></div>
        <div>Moyen</div>
    </div>
    <div class="flex flex-col items-center gap-2xs">
        <div class="h-[3px] w-[20px] bg-status-positive"></div>
        <div>Moins cher</div>
    </div>
</div>
