<script lang="ts">
    import cn from '$src/utils/cn';
    import MinusIcon from '$src/icons/MinusIcon.svelte';
    import PlusIcon from '$src/icons/PlusIcon.svelte';

    export let value: string;
    export let max: string | number | null | undefined = undefined;
    export let min: string | number | null | undefined = undefined;
    export let onIncrement: (event: Event) => void = () => {};
    export let onDecrement: (event: Event) => void = () => {};
    export let inputClasses: string = '';
    export let plusBtnClasses: string = '';
    export let minusBtnClasses: string = '';
    export let id = '';
    export let name = '';
    export let inputProps = {};
    export let minusBtnProps = {};
    export let plusBtnProps = {};
</script>

<div class="flex">
    <button
        type="button"
        class={cn(
            'h-4xl w-4xl touch-manipulation border border-r-0 border-solid border-border-quiet enabled:hover:bg-secondary-regular-container-hover enabled:active:bg-active-primary',
            minusBtnClasses,
        )}
        on:click={onDecrement}
        {...minusBtnProps}
    >
        <MinusIcon class="m-auto h-m fill-content-brand" />
    </button>
    <input
        {id}
        {name}
        type="number"
        class={cn(
            '!h-4xl !min-h-4xl !w-5xl !rounded-none border-b border-t border-solid !border-border-quiet text-center',
            'focus:!border-blue-brand',
            inputClasses,
        )}
        {max}
        {min}
        bind:value
        {...inputProps}
    />
    <button
        type="button"
        class={cn(
            'h-4xl w-4xl touch-manipulation border border-l-0 border-solid border-border-quiet enabled:hover:bg-secondary-regular-container-hover enabled:active:bg-active-primary',
            plusBtnClasses,
        )}
        on:click={onIncrement}
        {...plusBtnProps}
    >
        <PlusIcon class="m-auto h-m fill-content-brand" />
    </button>
</div>

<!--    Remove arrows inside input -->
<style>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type='number'] {
        -moz-appearance: textfield;
    }
</style>
