import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['input', 'fileList', 'button'];
    static values = {
        maxFiles: { type: Number, default: 3 },
    };

    get fileCount() {
        return this.fileListTarget.querySelectorAll('.file-item').length;
    }

    triggerFileSelect(event) {
        event.preventDefault();
        if (this.fileCount >= this.maxFilesValue) {
            return;
        }
        this.inputTarget.click();
    }

    handleFiles() {
        const files = this.inputTarget.files;
        const remainingSlots = this.maxFilesValue - this.fileCount;

        if (remainingSlots <= 0) {
            this.inputTarget.value = '';
            return;
        }

        const filesToAdd = Array.from(files).slice(0, remainingSlots);

        const dt = new DataTransfer();
        filesToAdd.forEach((file) => dt.items.add(file));
        this.inputTarget.files = dt.files;

        filesToAdd.forEach((file) => {
            const fileElement = this.createFileElement(file);
            this.fileListTarget.appendChild(fileElement);
        });
        this.updateButtonState();
    }

    createFileElement(file) {
        const span = document.createElement('span');
        span.classList.add(
            'file-item',
            'cursor-pointer',
            'w-[130px]',
            'py-xs',
            'px-s',
            'text-content-brand',
            'border',
            'border-solid',
            'border-content-brand',
            'rounded-full',
            'flex',
            'justify-between',
        );
        span.dataset.action = 'click->backpack--file-upload#removeFile';

        let filename = file.name;
        if (filename.length > 13) {
            filename = filename.substring(0, 13);
        }

        span.innerHTML = `
            ${filename}
            <button type="button">×</button>
        `;
        return span;
    }

    removeFile(event) {
        const fileItem = event.target.closest('.file-item');
        const fileName = fileItem.textContent.trim();

        const dt = new DataTransfer();
        const { files } = this.inputTarget;

        Array.from(files)
            .filter((file) => file.name !== fileName)
            .forEach((file) => dt.items.add(file));

        this.inputTarget.files = dt.files;
        fileItem.remove();
        this.updateButtonState();
    }

    updateButtonState() {
        if (this.fileCount >= this.maxFilesValue) {
            this.buttonTarget.disabled = true;
            this.buttonTarget.classList.add('disabled', 'cursor-not-allowed');
        } else {
            this.buttonTarget.disabled = false;
            this.buttonTarget.classList.remove('disabled');
        }
    }
}
