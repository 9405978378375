import { Controller } from '@hotwired/stimulus';
import intlTelInput from 'intl-tel-input';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    intlTelInput = null;
    inputFullField;

    static targets = ['input'];

    loadIntelInput() {
        this.intlTelInput = intlTelInput(this.inputTarget, {
            autoPlaceholder: 'off',
            initialCountry: 'fr',
            countryOrder: [
                'fr',
                'be',
                'ch',
                'de',
                'gb',
                'es',
                'nl',
                'pt',
                'us',
            ],
            separateDialCode: true,
            loadUtils: () => import('intl-tel-input/utils'),
        });

        // Add listeners once the library has been loaded.
        this.intlTelInput.promise.then(() => {
            this.inputTarget.addEventListener('countrychange', () =>
                this.formatInputValue(),
            );
            this.inputTarget.addEventListener('change', () =>
                this.formatInputValue(),
            );
            this.formatInputValue();
        });
    }

    initialize() {
        this.insertFullField();

        this.loadIntelInput();
    }

    formatInputValue() {
        if (!this.intlTelInput) {
            return;
        }

        const country = this.intlTelInput.getSelectedCountryData();

        // Format number (International format without prefix)
        this.inputTarget.value = this.intlTelInput
            .getNumber(intlTelInput.utils.numberFormat.INTERNATIONAL)
            .replace(new RegExp('^(\\+|00)' + country.dialCode), '')
            .trim();

        // Update full number (E164 format)
        this.inputFullField.value = this.intlTelInput.getNumber(
            intlTelInput.utils.numberFormat.E164,
        );
    }

    insertFullField() {
        this.inputFullField = document.createElement('input');
        this.inputFullField.setAttribute('type', 'hidden');
        this.inputFullField.name = `${this.inputTarget.name}_full`;
        this.inputTarget.after(this.inputFullField);
    }
}
