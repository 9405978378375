<script lang="ts">
    import PaxFilterForm from '$src/components/Filters/PaxFilterForm.svelte';
    import Button from '$src/components/Button.svelte';
    import { PaxFilterValues } from '$src/views/HomePage/SearchFilters/PaxFilter/types';

    // TODO dissociate nbPax in children and adults
    export let childrenAges: string[] = [];
    export let childrenValue: string;
    export let adultsValue: string;
    export let handleValidateSelection: (values: PaxFilterValues) => void;
</script>

<div class="h-[calc(100vh-76px)] w-full overflow-scroll p-2xl pb-6xl">
    <PaxFilterForm
        {childrenAges}
        {childrenValue}
        {adultsValue}
        handleSubmit={handleValidateSelection}
    >
        <!--  Footer validate button -->
        <div
            slot="footer"
            class="fixed bottom-0 left-0 flex w-full justify-between gap-5xl border-t border-solid border-commercial-neutral bg-white px-2xl py-m"
        >
            <Button
                type="button"
                intent="link"
                size="none"
                class="text-sm"
                on:click={() =>
                    handleValidateSelection({
                        adultsValue: '1',
                        childrenValue: '',
                        childrenAges: [],
                    })}
            >
                Effacer
            </Button>
            <Button type="submit" size="small" shape="rounded">Valider</Button>
        </div>
    </PaxFilterForm>
</div>
