<script lang="ts">
    import SearchBar from '$src/views/HomePage/SearchBar.svelte';
    import { DefaultOptionList, DefaultOptions } from '$src/types/search.types';
    import { flattenSportsFilter } from '$src/utils/searchFilters';

    export let defaultOptions: string;
    export let searchUrl: string;

    let defaultSportOptions: DefaultOptions = [];
    let defaultDestinationOptions: DefaultOptions = [];

    $: {
        const parsedOptions: DefaultOptionList = JSON.parse(defaultOptions);
        defaultDestinationOptions = parsedOptions?.destinations.sort((a, b) =>
            a.label.localeCompare(b.label),
        );

        // Flatten all children, not displaying parent sports
        defaultSportOptions = flattenSportsFilter(parsedOptions?.sports);
    }
</script>

<SearchBar {defaultDestinationOptions} {defaultSportOptions} {searchUrl} />
