import { Controller } from '@hotwired/stimulus';
import autoComplete from '@tarekraafat/autocomplete.js';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['url'];

    connect() {
        this.initializeAutocomplete();
    }

    initializeAutocomplete() {
        const autocompletes = document.querySelectorAll(
            '[data-autocomplete-url]',
        );
        autocompletes?.forEach((autocomplete) => {
            const autoCompleteJS = new autoComplete({
                selector: `#${autocomplete.id}`,
                data: {
                    src: async (query) => {
                        try {
                            const sourceUrl =
                                autoCompleteJS.input.attributes.getNamedItem(
                                    'data-autocomplete-url',
                                ).value;
                            const source = await fetch(
                                `${sourceUrl}?term=${query}`,
                            );
                            return await source.json();
                        } catch (error) {
                            return error;
                        }
                    },
                    keys: ['displayedName'],
                },
                resultsList: {
                    class: 'absolute w-full top-[50px] left-0 z-40 bg-container-quiet border border-solid border-border-quiet',
                },
                resultItem: {
                    highlight: true,
                    class: 'p-s cursor-pointer block relative z-40 hover:text-content-brand',
                },
                wrapper: false,
                debounce: 300,
                events: {
                    input: {
                        results: (event) => {
                            if (event.detail.results.length === 1) {
                                const value = event.detail.results[0].value;
                                this.selectOptionByValue(autoCompleteJS, value);
                            }

                            if (event.detail.results.length === 0) {
                                this.resetSelect(autoCompleteJS);
                            }
                        },
                        selection: (event) => {
                            const value = event.detail.selection.value;
                            autoCompleteJS.input.value = value;
                            autoCompleteJS.input.value = value.displayedName;
                            this.selectOptionByValue(autoCompleteJS, value);
                        },
                    },
                },
            });
        });
    }

    selectOptionByValue(autoCompleteJS, value) {
        const select = document.querySelector(
            autoCompleteJS.input.attributes.getNamedItem(
                'data-autocomplete-target',
            ).value,
        );
        const options = Array.from(select.getElementsByTagName('option'));
        options.forEach((e) => {
            if (e.value === String(value.id)) {
                e.selected = true;
            } else if (e.selected === true) {
                e.selected = false;
            }
        });
    }

    resetSelect(autoCompleteJS) {
        const select = document.querySelector(
            autoCompleteJS.input.attributes.getNamedItem(
                'data-autocomplete-target',
            ).value,
        );
        select.selectedIndex = 0;
    }
}
