<!-- TODO merge with MultiSelectFilter as one Select component with "multi" option -->
<script lang="ts">
    import { ComponentType } from 'svelte';

    import SelectFilterDesktop from '$src/components/Filters/desktop/SelectFilterDesktop.svelte';
    import { DefaultOptions } from '$src/types/search.types';
    import { filterOptions } from '$src/utils/searchFilters';

    export let defaultOptions: DefaultOptions;
    export let inputValue: string;

    export let icon: ComponentType;
    export let placeholder: string;
    export let inputLabel: string;
    export let menuTitle: string | undefined;
    let filteredOptions = defaultOptions;

    const handleFilterOptions = (query: string) => {
        filteredOptions = filterOptions({ query, defaultOptions });
    };

    const onInputChange = (event: Event) =>
        handleFilterOptions((event.target as HTMLInputElement).value);

    const resetInput = () => {
        // reset filtered options
        handleFilterOptions('');
    };
</script>

<SelectFilterDesktop
    bind:inputValue
    {onInputChange}
    {resetInput}
    {filteredOptions}
    {defaultOptions}
    {inputLabel}
    {menuTitle}
    {icon}
    {placeholder}
/>
